@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

@font-face {
  font-family: 'TWK Lausanne';
  font-style: normal;
  font-weight: 300;
  src: url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-300.woff2') format('woff2'), /* Super Modern Browsers */ url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-300.woff') format('woff'), /* Modern Browsers */ url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-300.ttf') format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'TWK Lausanne';
  font-style: normal;
  font-weight: 400;
  src: url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-400.woff2') format('woff2'), /* Super Modern Browsers */ url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-400.woff') format('woff'), /* Modern Browsers */ url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-400.ttf') format('truetype') /* Safari, Android, iOS */
}
@font-face {
  font-family: 'TWK Lausanne';
  font-style: normal;
  font-weight: 500;
  src: url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-500.woff2') format('woff2'), /* Super Modern Browsers */ url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-500.woff') format('woff'), /* Modern Browsers */ url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-500.ttf') format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'TWK Lausanne';
  font-style: normal;
  font-weight: 600;
  src: url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-600.woff2') format('woff2'), /* Super Modern Browsers */ url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-600.woff') format('woff'), /* Modern Browsers */ url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-600.ttf') format('truetype') /* Safari, Android, iOS */
}

@font-face {
  font-family: 'TWK Lausanne';
  font-style: normal;
  font-weight: 700;
  src: url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-700.woff2') format('woff2'), /* Super Modern Browsers */ url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-700.woff') format('woff'), /* Modern Browsers */ url('https://assets.withfaye.com/fonts/TWKLausanne/TWKLausanne-700.ttf') format('truetype') /* Safari, Android, iOS */
}

:root{
  --agent-font-family: 'TWK Lausanne', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  --red:#C73939;
  --green:#6EC103;
  --blue:#5076FF;
  --yellow:#FFC800;
  --orange:#F87830;
  --purple:#8668FF;
  --pink:#FF6FA2;
  --gray:#959493;
  --primary-color: #4726D1;
  --secondary-color: #EDC3F3;
  --primery-background: #959493;
  --navbar-background: #F7FCFF;
  --primary-text-color:#434140 ;
  --secondary-text-color: #959493;
  --trip-status-in-trip: #F4B300;
  --trip-status-post-trip: #87C0D5;
  --trip-status-pre-trip: #8ACD85;
  --policy-status-active: #8acd85;
  --policy-status-not-active: #ea0e0e;
  --text-warning: #F7446D;
--box-shadow:  0px 4px 20px rgba(0, 0, 0, 0.12);

;


;
}


p, span, a {
  font-family: var(--agent-font-family);
}
body {
  margin: 0;
  font-family: var(--agent-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ag-theme-alpine{
  font-family: var(--agent-font-family);
}

#root{
  height: 100vh;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
